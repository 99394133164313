/**
 * des: 公共工具类
 */

/**
 * 跨应用路由跳转
 * @param {String} href url地址
 * @param {Object} stateObj 状态参数 可以通过history.state读取
 * @param {String} module
 */
function routerGo(path, module = null, stateObj = null) {
    window.history.pushState(stateObj, module, path);
}

export {
    routerGo // 跨应用路由跳转
}
