import chLocale from 'element-ui/lib/locale/lang/zh-CN'

const ch = {
    ...chLocale,
    welcome: '欢迎登录',
    setting: '设置',
    confirm: '确定',
    save: '保存',
    cancel: '取消',
    loading: '执行中',
    del: '删除',
    edit: '编辑',
    view: '查看',
    disable: '禁用',
    resetpass: '重置密码',
    updatepass: '修改密码',
    confirm_password_change:'确认修改密码？',
    Tips:'提示',
    yes: '是',
    no: '否',
    oldRepass: '旧密码',
    newRepass: '新密码',
    confirmRepass: '确认密码',
    personalInfo: '个人信息',
    head_portrait:'头像',
    name:'名称',
    department:'部门',
    role:'角色',
    phone:'手机号码',
    email:'邮箱',
    sms_reception:'短信接收',
    email_reception:'邮件接收',
    receive_or_not:'是否接收',
    data_Auth: '数据授权',
    rem_pass: '记住密码',
    slide_right: '请向右滑动',
    login: "登录",
    logout: "退出",
    loginSuccess: "登录成功",
    logoutSuccess: "退出成功",
    loginFaild: "账号或密码错误",
    forget_pass: '忘记密码?',
    you_cancel_contr: "你已取消操作",
    sumbit: '提交',
    back: '返回',
    inp_name_tip: '只能为中文、数字、字母、@、_、-',
    inp_num_tip: '只能为1-100合法整数',
    inp_numMonth_tip: '当前保养周期只能为1-12合法整数',
    inp_numWeek_tip: '当前保养周期只能为1-4合法整数',
    inp_time_tip: '请输入时间',
    sel_time_tip: '请选择时间',
    inp_cycle_tip: '请输入周期',
    inp_accou_tip: '只能为数字、字母、@、_',
    inp_pass_tip: '只能为数字、字母、@、_',
    length_35: '长度不得超过35',
    new_old_diff: '密码不一致',
    user_err_tip: '含有除`_、@`之外的特殊字符',
    length_0_to_30: '长度必须在2-30之间',
    length_in: '长度不得超过',
    inp_phone_tip: '手机号格式不正确',
    inp_email_tip: '邮箱格式不正确',
    slide_verify: '请滑动验证',
    slide_success: '滑动成功',
    demonstration_platform:"演示平台",
    notice_messages:"通知",
    alarm_messages:"告警",
    system_messages:"系统",
    message_time:"消息时间：",
    more_news:"更多消息 ...",
    contr_success: '操作成功',
    contr_fail: '操作失败！',
    you_cancel_contr: "你已取消操作",
    upload_file:'上传头像图片大小不能超过 5MB！',
}
export default ch
