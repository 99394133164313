/**
 * 请求状态码
 * @type {{code: {ok: number, err: number}}}
 */
const GLOBAL = {
  code: {
    ok: 200,
    err: 300
  }
}

export default GLOBAL