// 配置js文件路径

// 开发
export default {
    authAccount: "hiwooiot",
    // BASEURL: 'http://192.168.0.141',
    // BASEURL: 'https://www.hiwooiot.icu',
    // BASEURL: 'https://www.03165553721.com',
    BASEURL: window.location.origin,

    // WEBSOCKET_SERVER_URL: "wss://www.hiwooiot.icu/springboot/app-basic",
    WEBSOCKET_SERVER_URL: "wss://www.hiwooiot.cn/springboot/app-basic",
    // WEBSOCKET_SERVER_URL: "ws://192.168.0.141:8781",

    // WEBSOCKET_SERVER_URL: "wss://www.03165553721.com/springboot/app-basic",


    environment_protection: "https://ep.hiwooiot.cn",
    energy: "https://es.hiwooiot.cn",
    factory: "https://pe.hiwooiot.cn",
    other: "https://vpn.hiwooiot.cn",

    // maintenance: '8784/',
    maintenance: "/springboot/app-maintenance/",
    userMessage: {
        getUserMessage: "/message/getUserMessage",
        readUserMessage: "/message/readUserMessage",
    },

    // basic: ":8782/",
    basic: "/springboot/app-basic/",

    userSet: "user/register",
    editUser: "user/editUser",
    login: "user/loginCommoner",
    loginOut: "user/loginout",
    repass: "user/editPassword",
    resetPass: "user/resetPassword",

    getMenu: "jurisdiction/getMenuByUser",
    getNotificationMessage: "msg/getMessage",
    getRoleJurisdiction: "role/getRoleJurisdiction",
    newestMessage: "upgradeNotification/newestMessage",
    readNotificationMessage: "msg/readNotificationMessage",
    readAlarmMessage: "msg/readAlarmMessage",
}
