import store from "@/store";
import {DataType} from "wl-core"

/**
 * @name 声明一个常量准备将props内的部分内容储存起来
 */
const STORE = {};
/**
 * @name 启动qiankun应用间通信机制
 * @param {Function} initGlobalState 官方通信函数
 * @description 注意：主应用是从qiankun中导出的initGlobalState方法，
 * @description 注意：子应用是附加在props上的onGlobalStateChange, setGlobalState方法（只用主应用注册了通信才会有）
 */
const appStore = (initGlobalState) => {
    /**
     * @name 初始化数据内容
     */
    const {onGlobalStateChange, setGlobalState} = initGlobalState({
        msg: '',
        token: '',
        appsRefresh: false,
    });

    /**
     * @name 监听数据变动
     * @param {Function} 监听到数据发生改变后的回调函数
     * @des 将监听到的数据存入vuex
     */
    onGlobalStateChange((value, prev) => {
        if (value.msg.userInfo) {
            if ("string" == typeof value.msg.userInfo) {
                value.msg.userInfo = JSON.parse(value.msg.userInfo)
            }
            store.dispatch('app/setUserInfo', value.msg.userInfo);
            store.dispatch('app/setToken', value.msg.userInfo.token);
            // value.appsRefresh && window?.location?.reload?.();
        }
    });

    /**
     * @name 改变数据并向所有应用广播
     */
    let userInfo = store.getters.userInfo || sessionStorage.getItem("userInfo")
    let roleInfo = store.getters.roleInfo || sessionStorage.getItem("roleInfo")
    setGlobalState({
        ignore: 'master',
        msg: {userInfo: userInfo, roleInfo: roleInfo, menu: store.getters.menu},
        token: '',
        appsRefresh: false,
    });

    /**
     * @name 将你需要的数据存起来，供下面setState方法使用
     */
    STORE.setGlobalState = setGlobalState;
}

/**
 * @name 全局setState方法，修改的内容将通知所有微应用
 * @param {Object} data 按照你设定的内容格式数据
 */
const setState = (data) => {
    if (!DataType.isObject(data)) {
        throw Error('data必须是对象格式');
    }
    STORE.setGlobalState?.({
        ...data
    })
}

export {
    setState
}

export default appStore;