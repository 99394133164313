import router from './router'
import store from './store'
import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import url from '../public/url'
import * as ElIconModules from '@element-plus/icons-vue'
import {createI18n} from 'vue-i18n'
import messages from './lang'
import 'element-ui/lib/theme-chalk/index.css'


const i18n = createI18n({
    fallbackLocale: 'ch',
    globalInjection: true,
    legacy: false, // you must specify 'legacy: false' option
    locale: sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'ch',
    messages,
});
/**
 * @name 验证登陆身份并启动微应用
 */
import microAppStart from './core/auth'

const app = createApp(App)

for (const key in url) {
    app.config.globalProperties['$' + key] = url[key]
}

export function transElIconName(iconName) {
    return 'i' + iconName.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase())
}

for (let iconName in ElIconModules) {
    app.component(iconName, ElIconModules[iconName])
}

// app.config.globalProperties.config.productionTip = false;

app.use(router).use(store).use(ElementPlus).use(i18n).mount("#main-container")

microAppStart()
