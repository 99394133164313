import enLocale from 'element-ui/lib/locale/lang/en'

const en = {
    ...enLocale,
    welcome: 'Welcome To Cloud',
    confirm: 'Confirm',
    cancel: 'Cancel',
    save: 'Save',
    loading: 'Loading',
    edit: 'Edit',
    del: 'Delete',
    view: 'View',
    disable: 'Forbidden',
    resetpass: 'Reset Password',
    updatepass: 'Change Password',
    confirm_password_change:'Confirm password change?',
    Tips:'Tips',
    yes: 'YES',
    no: 'NO',
    personalInfo: 'Personal Info',
    head_portrait:'Head Portrait',
    name:'Name',
    department:'Department',
    role:'Role',
    phone:'Phone',
    email:'email',
    sms_reception:'SMS Reception',
    email_reception:'Email Reception',
    receive_or_not:'Receive Or Not',
    data_Auth: 'Data Authorization',
    oldRepass: 'Old Password',
    newRepass: 'New Password',
    confirmRepass: 'Confirm Password',
    rem_pass: 'Remmember Password',
    slide_right: 'Please Slide Right',
    login: "Login",
    logout: "Logout",
    loginSuccess: "Login Successfully",
    logoutSuccess: "Logout Successfully",
    loginFaild: "Wrong account or password",
    forget_pass: 'Forget Password?',
    you_cancel_contr: "You have cancelled the operation",
    sumbit: 'Submit',
    back: 'back',
    inp_name_tip: 'It can only be Chinese, numbers, letters, @_ 、-',
    inp_num_tip: 'It can only be numbers',
    inp_accou_tip: 'It can only be numbers, letters, _ 、-',
    length_35: 'The length shall not exceed 35',
    new_old_diff: 'Password inconsistency',
    slide_verify: 'Please slide to verify',
    slide_success: 'Slide Succeed',
    demonstration_platform:"Demonstration Platform",
    notice_messages:"Notice",
    alarm_messages:"Alarm",
    system_messages:"System",
    message_time:"Message Time:",
    more_news:"More News ...",
    contr_success: 'Handle Success!',
    contr_fail: 'Handle Fail!',
    you_cancel_contr: "You have cancelled the operation",
    upload_file:'The size of uploaded avatar image cannot exceed 5MB!',
}
export default en
