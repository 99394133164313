import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {path: '/', redirect: '/cloud-login/index'},
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
})

export default router
