import URL from '../../public/url'
import axios from 'axios'
import store from '../store/index'

const path = URL.BASEURL
let http = axios.create({
    baseUrl: path,
    timeout: 5000
})

//3、axios的拦截--request
http.interceptors.request.use(config => {
    // 请求成功处理
    config.headers.token = store.state.app.userInfo.token
    return config;
}, err => {
    Promise.reject(err);// 请求错误处理
})

//4、axios的拦截--response
http.interceptors.response.use(response => {
    if (response.data.code === 200) {
        // console.log('请求成功')
    }
    if (response.data.code === 300) {
        // console.log(response.data.msg)
    }
    return response;
}, err => {
    if (err.response.code === 301) {
        // console.log('登录过期');
        setTimeout(() => {
            this.$router.replace({
                path: '/index',
            });
        }, 1000);
        sessionStorage.removeItem('token');//清除token
    }
    if (err.response.code === 500) {
        // console.log('请联系管理员');
    }
    return Promise.reject(err);
})

//5、get请求的封装
export function apiGet(url, params = {}) {
    return new Promise((resolve, reject) => {
        http({
            url: path + url,
            method: 'get',
            params: params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

//6、post请求封装  默认json格式:'Content-Type':'application/json',如果是文件上传，可以修改headers为 headers: { 'Content-Type': 'multipart/form-data' }
export function apiPost(url, params = {}, headers = {'Content-Type': 'application/x-www-form-urlencoded'}) {
    return new Promise((resolve, reject) => {
        http({
            url: path + url,
            method: 'post',
            data: params,
            headers: headers
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export default {
    apiGet,
    apiPost
}

